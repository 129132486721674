import React from "react";
import { cn } from "../../../lib/utils";
import { FeaturesSectionDemo } from "../../ui/FeaturesSectionDemo";
import { AboutUs } from "../../ui/bentogrid";

const Features = () => {
  return (
    <section className="relative border-gray-200 bg-slate-100 dark:bg-slate-900 duration-[1000ms] overflow-hidden">
      <AboutUs />
      <div className="bg-slate-100 dark:bg-slate-900 pt-40">
        <div className="px-8 pb-12">
          <h4 className="text-3xl lg:text-5xl lg:leading-tight max-w-5xl mx-auto text-center tracking-tight font-medium text-black dark:text-white">
            Packed with tons of features
          </h4>
        </div>
        <FeaturesSectionDemo />
      </div>
    </section>
  );
};

export default Features;
