import React from "react";
import { cn } from "../../../lib/utils";

const Footer: React.FC = () => {
  return (
    <footer className="bg-slate-100 dark:bg-slate-900 text-black dark:text-white py-8 pt-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h2 className="text-2xl font-bold">Kairos AI</h2>
            <p className="mt-2">© 2024 A Gemini competition app</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
