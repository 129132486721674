import React, { useState } from "react";
import { Link } from "react-router-dom";
import DarkModeSwitcher from "../../ui/DarkModeSwitcher";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  const onClose = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <header className="dark:bg-slate-900 bg-slate-100 duration-[1000ms] z-30">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a className="flex items-center space-x-3 rtl:space-x-reverse">
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-black dark:text-white">
              <span className="text-blue-500">K</span>airos
              <span className="text-blue-500">AI</span>
            </span>
          </a>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <div className="pr-2 pt-1">
              <DarkModeSwitcher />
            </div>
            <Link to="https://ai.google.dev/competition/projects/kairos-ai">
              <button
                type="button"
                className="text-white bg-blue-400 focus:outline-none hover:bg-blue-500 focus:ring-4 focus:ring-zinc-100 font-medium rounded-xl text-sm px-5 py-2.5 me-2 mb-2 dark:hover:border-zinc-600 dark:focus:ring-zinc-700"
              >
                Vote for us
              </button>
            </Link>
            <button
              data-collapse-toggle="navbar-cta"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 duration-1000"
              aria-controls="navbar-cta"
              aria-expanded={isOpen}
              onClick={toggleNavbar}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between ${isOpen ? "block" : "hidden"} w-full md:flex md:w-auto md:order-1`}
            id="navbar-cta"
          >
            <ul className="duration-[1000ms] flex flex-col font-medium py-4 md:p-0 mt-4 border border-slate-700 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 text-center">
              <li>
                <a
                  href="#home"
                  className="block py-2 px-3 md:p-0 text-blue-400 rounded md:bg-transparent md:text-blue-400 dark:text-blue-400"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-slate-300 hover:bg-transparent hover:text-blue-400 dark:hover:text-blue-400 dark:text-white text-blue-500 dark:hover:text-blue-400 dark:hover:bg-transparent dark:border-gray-700"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#features"
                  className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-slate-300 hover:bg-transparent hover:text-blue-400 dark:hover:text-blue-400 dark:text-white text-blue-500 dark:hover:text-blue-400 dark:hover:bg-transparent dark:border-gray-700"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  href="#approach"
                  className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-slate-300 hover:bg-transparent hover:text-blue-400 dark:hover:text-blue-400 dark:text-white text-blue-500 dark:hover:text-blue-400 dark:hover:bg-transparent dark:border-gray-700"
                >
                  Approach
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
