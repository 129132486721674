import React from "react";
import { ContainerScroll } from "../../ui/container-scroll-animation";
import { Spotlight } from "../../ui/spotlight";
import { cn } from "../../../lib/utils";

type Props = {};

const Hero = (props: Props) => {
  return (
    <section className="border-gray-200 bg-slate-100 dark:bg-slate-900 duration-[1000ms] overflow-hidden">
      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="white"
      />
      <div className="flex flex-col overflow-hidden">
        <ContainerScroll
          titleComponent={
            <>
              <h1 className="text-4xl font-semibold dark:text-white text-black">
                Unleash the power of <br />
                <span className="text-4xl md:text-[6rem] font-bold mt-1 leading-none">
                  Your <span className="text-blue-400">AI</span> Project
                  Planner.
                </span>
              </h1>
            </>
          }
        >
          <img
            src={`${process.env.PUBLIC_URL}/ProjectPage.png`}
            alt="hero"
            className="mx-auto rounded-2xl object-cover w-full max-w-screen-xl h-auto"
            draggable={false}
          />
        </ContainerScroll>
      </div>
    </section>
  );
};

export default Hero;
