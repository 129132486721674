import React, { useState } from "react";
import "./../../index.css";
import { Link } from "react-router-dom"
import Navbar from "./landing/nav";
import Hero from "./landing/hero";
import Footer from "./landing/footer";
import Features from "./landing/features";

const Landing = () => {

  return (
    <>
      <Navbar />
      <Hero />
      <div
        className="absolute inset-x-0 top-[100%] z-[10] transform-gpu overflow-hidden blur-3xl"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ffffff] to-[#60a5fa] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <Features />
      <div className="bg-slate-100 dark:bg-slate-900 pt-40">
        <div className="max-w-5xl px-4 xl:px-0 py-10 lg:pt-20 lg:pb-20 mx-auto">
          <div className="max-w-3xl mb-10 lg:mb-14">
            <h2 className="text-black dark:text-white font-semibold text-2xl md:text-4xl md:leading-tight">
              Our approach
            </h2>
            <p className="mt-1  text-slate-400">
              We utilise Google's Gemini AI model to offer the best analysis,
              and we do that in the following order.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 lg:items-center">
            <div className="aspect-w-16 aspect-h-9 lg:aspect-none">
              <img
                className="w-full object-cover rounded-xl"
                src="https://images.unsplash.com/photo-1587614203976-365c74645e83?q=80&w=480&h=600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Features Image"
              />
            </div>

            <div>
              <div className="mb-4">
                <h3 className="text-blue-400 text-xs font-medium uppercase">
                  Steps
                </h3>
              </div>
              <div className="flex gap-x-5 ms-1">
                <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-slate-800">
                  <div className="relative z-10 size-8 flex justify-center items-center">
                    <span className="flex shrink-0 justify-center items-center size-8 border border-slate-800 text-blue-400 font-semibold text-xs uppercase rounded-full">
                      0
                    </span>
                  </div>
                </div>
                <div className="grow pt-0.5 pb-8 sm:pb-12">
                  <p className="text-sm lg:text-base  text-slate-400">
                    <span className="text-black dark:text-white font-bold">
                      Run your project idea by Gemini:{" "}
                    </span>
                    Receive your project idea and run it by Google Gemini for
                    analysis.
                  </p>
                </div>
              </div>
              <div className="flex gap-x-5 ms-1">
                <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-slate-800">
                  <div className="relative z-10 size-8 flex justify-center items-center">
                    <span className="flex shrink-0 justify-center items-center size-8 border border-slate-800 text-blue-400 font-semibold text-xs uppercase rounded-full">
                      1
                    </span>
                  </div>
                </div>
                <div className="grow pt-0.5 pb-8 sm:pb-12">
                  <p className="text-sm lg:text-base  text-slate-400">
                    <span className="text-black dark:text-white font-bold">
                      Market Research and Analysis:{" "}
                    </span>
                    Identify your goals, and conduct market research for your
                    project.
                  </p>
                </div>
              </div>

              <div className="flex gap-x-5 ms-1">
                <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-slate-800">
                  <div className="relative z-10 size-8 flex justify-center items-center">
                    <span className="flex shrink-0 justify-center items-center size-8 border border-slate-800 text-blue-400 font-semibold text-xs uppercase rounded-full">
                      2
                    </span>
                  </div>
                </div>
                <div className="grow pt-0.5 pb-8 sm:pb-12">
                  <p className="text-sm lg:text-base  text-slate-400">
                    <span className="text-black dark:text-white font-bold">
                      Project Plan Development:{" "}
                    </span>
                    Develop a project plan including finances, risk analysis and
                    whatever your project might need.
                  </p>
                </div>
              </div>

              <div className="flex gap-x-5 ms-1">
                <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-slate-800">
                  <div className="relative z-10 size-8 flex justify-center items-center">
                    <span className="flex shrink-0 justify-center items-center size-8 border border-slate-800 text-blue-400 font-semibold text-xs uppercase rounded-full">
                      3
                    </span>
                  </div>
                </div>
                <div className="grow pt-0.5 pb-8 sm:pb-12">
                  <p className="text-sm md:text-base  text-slate-400">
                    <span className="text-black dark:text-white font-bold">
                      Provide clear instruction and documentation:{" "}
                    </span>
                    Generate clear instructions and documents for your project.
                  </p>
                </div>
              </div>

              <div className="flex gap-x-5 ms-1">
                <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-slate-800">
                  <div className="relative z-10 size-8 flex justify-center items-center">
                    <span className="flex shrink-0 justify-center items-center size-8 border border-slate-800 text-blue-400 font-semibold text-xs uppercase rounded-full">
                      4
                    </span>
                  </div>
                </div>
                <div className="grow pt-0.5 pb-8 sm:pb-12">
                  <p className="text-sm md:text-base  text-slate-400">
                    <span className="text-black dark:text-white font-bold">
                      Optimization:{" "}
                    </span>
                    Allow you to edit your project however you'd like, whenever
                    you like, while re-drafting the instructions, documentation
                    and data.
                  </p>
                </div>
              </div>

              <a
                className="group inline-flex items-center gap-x-2 py-2 px-3 bg-blue-400 font-medium text-sm text-white rounded-full focus:outline-none"
                href="#"
              >
                Start now
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-slate-100 dark:bg-slate-900 py-40">
        <div>
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-7xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              Support<span className="text-blue-400 font-bold"> us </span><br />
              now!
            </h2>
            <div className="pt-1">
              <Link 
              to="https://ai.google.dev/competition/projects/kairos-ai"
              >
              <button
                className="text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >
                Vote for us
              </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Landing;
